<template>

  <!-- Table Container Card -->
  <b-card
    title="Prodotti Assicurativi"
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Entries</label> -->
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'add-edit-product', params: { id: 'new' }}"
          >
            Nuovo
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Cerca..."
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refProductListTable"
      :items="fetchProducts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(Name)>
        <feather-icon
          icon="ShieldIcon"
          class="mx-auto"
        /> Prodotto
      </template>
      <template #head(company.company)>
        <feather-icon
          icon="GlobeIcon"
          class="mx-auto"
        /> Compangia
      </template>
      <template #head(IsEnabled)>
        <feather-icon
          icon="CheckIcon"
          class="mx-auto"
        /> Attivo
      </template>

      <template #head(actions)>
        <feather-icon
          icon="ZapIcon"
          class="mx-auto"
        /> Azioni
      </template>
      <template #cell(Name)="data">
        <b-link
          :to="{ name: 'add-edit-product', params: { id: data.item.Id }}"
          class="font-weight-bold"
        >
          {{ data.item.Name }}
        </b-link>
      </template>
      <template #cell(company.company)="data">
        <b-link
          :to="{ name: 'companylist' }"
        >
          {{ data.value }}
        </b-link>
      </template>
      <!-- Column: IsEnabled -->
      <template #cell(IsEnabled)="data">
        <b-form-checkbox
          v-model="data.value"
          input-id="is-enabled-product"
          switch
          vertical-align="center"
          disabled
          value="Y"
          unchecked-value="N"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <!-- <feather-icon
            :id="`product-row-${data.item.Id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'apps-product-preview', params: { id: data.item.Id }})"
          />
          <b-tooltip
            title="Visualizza"
            :target="`product-row-${data.item.Id}-preview-icon`"
          />
          <feather-icon
            :id="`product-row-${data.item.Id}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="addEditProductModal(data.item.Id)"
          />
          <b-tooltip
            title="Modifica"
            :target="`product-row-${data.item.Id}-edit-icon`"
          /> -->
          <feather-icon
            :id="`product-row-${data.item.Id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="deleteRole(data.item.Id, data.item.Name)"
          />
          <b-tooltip
            title="Elimina"
            :target="`product-row-${data.item.Id}-delete-icon`"
          />
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalProducts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BPagination, BTooltip, BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useProductsList from './useProductList'

import productStoreModule from './productStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BFormCheckbox,
    BLink,
    BPagination,
    BTooltip,
    vSelect,
  },
  methods: {
    addEditProductModal(id) {
      this.$bvModal.show(`add-product-${id}`)
    },
    deleteRole(id, name) {
      this.$swal({
        title: `Eliminare ${name}?`,
        text: "Attenzione non puoi annullare l'operazione!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, elimina!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-product/DeleteProduct', { id })
            .then(response => {
              console.log(response)
              if (response.data.ResultCode == 0) {
                this.$swal({
                  icon: 'success',
                  title: 'Eliminato!',
                  text: `Il prodotto assicurativo ${name} è stato eliminato.`,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(res => {
                  this.refetchData()
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.ResultMessage,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
              // this.$router.go()
            })
            .catch(error => {
              if (error.response.status === 404) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error deleting user',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
  setup() {
    const COMPANY_DEALER_STORE_MODULE_NAME = 'app-product'

    // Register module
    if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })

    const {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,

      refetchData,

    } = useProductsList()

    return {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,

      refetchData,

      avatarText,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .product-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

